<template>
  <base-section id="experience-capabilities">
    <base-section-heading title="core competencies">
      <h3>
      NITRILE GLOVE MANUFACTURING
      </h3>
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(experience, i) in experiences"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="experience"
            align="left"
            horizontal
          >
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionExperienceCapabilities',

    data: () => ({
      experiences: [
        {
          title: 'DESIGN',
          icon: 'mdi-calendar',
          dark: true,
          color: 'primary',
          text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
          title: 'FABRICATION',
          icon: 'mdi-factory',
          dark: true,
          color: 'primary',
          text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
          color: 'primary',
          dark: true,
          title: 'INSTALLATION',
          icon: 'mdi-earth',
          text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
          title: 'COMMISSIONING',
          icon: 'mdi-flask-empty-outline',
          dark: true,
          color: 'primary',
          text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
          title: 'PRODUCTION',
          icon: 'mdi-certificate',
          dark: true,
          color: 'primary',
          text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
      ],
    }),
  }
</script>
